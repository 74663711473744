import "./includes/jquery-ui.min.js"

import "tipso"
import "hoverintent"
import "daterangepicker"

window.moment = require('moment'); // require

const PubNub = require("pubnub");
const flatpickr = require("flatpickr");
window.AudioStreamMeter = require('audio-stream-meter');

import adapter from 'webrtc-adapter';

import "./includes/otDialog.js"
import "./includes/jquery.showerror.js"
import "./includes/jquery.ajaxSearch.js"
import "./includes/igrowl.js"

import "./includes/webrtc/DetectRTC.min.js"
import "./includes/speedtest.js"
import "./includes/autolink.js"
import "./includes/selectize/selectize.js"
import "./includes/jquery.nicescroll.min.js"

var ringTimeOut = false;
var pushMessenger = false;

//var showTermsConditionsModal = (showTermsConditionsModal === undefined) ? false : showTermsConditionsModal;

window.$ = window.jQuery = require('jquery');


window.addEventListener('load', () => {

	$('#nav-icon').click(function(){ $('#topnav').toggle(); 	});


	if (loggedInUser)
	{
		setInterval(function(){ $.post('/ajax/_pulse.php'); }, 20000);

		pushMessenger = new PubNub({
			subscribeKey : pushSubscribeKey,
			ssl: true,
			verify_peer: false
		});
		pushMessenger.addListener({
			message: function(dataReceived) {
				//console.log('dataReceived',dataReceived);

				if (dataReceived.message.event=='new_message' && typeof handleNewMessageNotification==="function")
					handleNewMessageNotification(dataReceived.message);
				else
					handleNewPushNotification(dataReceived.message);
			}
		});

		pushMessenger.subscribe({
		    channels: [pushChannel]
		});


		if (!viewingClassroom && !disableDiagnostics)
		{
			if (showTermsConditionsModal && !getCookie('termsconditions'))
			{
				$('#modal_edit_3 .modalcontents').load('/agencytermsconditions');
				showModal('#modal_edit_3',600);
			}

			if (!getCookie('diagnosticsrun'))
			{
				//Initial diagnostics modal
				DetectRTC.load(function(){
					loadModal('/diagnosticsmodal','#modal_edit',680);
				});
			}
			else if (getCookie('diagnosticsrun')=='skipped')
			{
				showDiagnosticsWarning();
			}
		}

		$('.requires-rtc').click(function(){
			if ($(this).hasClass('disabled'))
			{
				showRTCPermissionModal();
				return false;
			}
		});

	}

});

window.showDiagnosticsWarning = function(){
	$('#diagnostics-warning-bar').remove();
	$('body').prepend('<div id="diagnostics-warning-bar"><i class="fa fa-exclamation-triangle"></i> Please click here to check your system is ready to use the classroom</div>');
	$('#diagnostics-warning-bar').click(function(){
		DetectRTC.load(function(){
			loadModal('/diagnosticsmodal','#modal_edit',680);
		});
	});
}

function handleNewPushNotification(data)
{
	//console.log('New PUSH NOTIFICATION',data);
	if (viewingClassroom && data.type=='classroom')
	{
		if (data.event=='close_all_other_sessions' && (sessionId!=data.session_id || connectionId!=data.connection_id))
		{
			disableClassroomSession();
		}
		else if (data.event=='end_session' && sessionId==data.session_id)
		{
			endClassroomSession();
		}
	}
	else if (!viewingClassroom)
	{
		if (data.event=='new_login')
		{
			var event = new CustomEvent("new_login", {detail: { "user_type": data.user_type, "user_id": data.user_id } });
			document.dispatchEvent(event);
		}
		else if (data.event=='new_logout')
		{
			var event = new CustomEvent("new_logout", {detail: { "user_type": data.user_type, "user_id": data.user_id } });
			document.dispatchEvent(event);
		}
		else if (data.event=='pulse')
		{
			var event = new CustomEvent("pulse", {detail: { "user_type": data.user_type, "user_id": data.user_id } });
			document.dispatchEvent(event);
		}
		else if (data.event=='lesson_created')
		{
			var event = new CustomEvent("lesson_created", {detail: { "lesson_id": data.lesson_id } });
			document.dispatchEvent(event);
		}
		else if (data.event=='lesson_updated')
		{
			var event = new CustomEvent("lesson_updated", {detail: { "lesson_id": data.lesson_id } });
			document.dispatchEvent(event);
		}
		else if (data.event=='new_message')
		{
			checkMessageNotifications(true);
		}
	}
}


window.checkMessageNotifications = function(doBeep)
{
	if (doBeep===undefined) doBeep = true;

	var oldNotificationCount = 0, newNotificationCount = 0;

	$('#'+loggedInUserType+'-main-menu .number-notification').each(function(){
		oldNotificationCount+= parseInt($(this).text());
	});

	$('#'+loggedInUserType+'-main-menu .number-notification').remove();

	$.post('/menu-notifications',{ },function(data){
		var response = JSON.parse(data);

		if (response.unread_messages>0)
		{
			$('#'+loggedInUserType+'-main-menu li[data-parent-menu="messages"] > a').append('<span class="number-notification">'+response.unread_messages+'</span>');

			$('#'+loggedInUserType+'-main-menu .number-notification').each(function(){
				newNotificationCount+= parseInt($(this).text());
			});

			if (doBeep && newNotificationCount>oldNotificationCount)
				$('#sound_ping').trigger('play');

		}

	});
}

window.showRTCPermissionModal = function()
{
	$('#modal_edit .modalcontents').html('<h1>Audio &amp; Video Access</h1>\
		<p>In order for TutorBranch to work correctly, you\'ll need a working webcam. Once you\'ve connected your webcam, please click the button below to enable TutorBranch to access it.</p>\
		<p><span class="fullwidth button request-rtc-permission"><i class="fa fa-video-camera"></i> Ok, my webcam is plugged in</span></p>\
		<p><a class="skipstep" onclick="closeModal(\'#modal_edit\');">Skip this step, I don\'t need the website to work properly right now</a></p>\
		');
	showModal('#modal_edit',550);
	$('.request-rtc-permission').click(function(){
		$('#modal_edit .modalcontents').html('Please wait, checking...');
		navigator.mediaDevices.getUserMedia( { video:true, audio: true } )
			.then(function(stream) {
				showNotice('Webcam Connected','Your webcam is now connected','success');
				$('.requires-rtc').removeClass('disabled');
				$('#browsernotice').remove();
				closeModal('#modal_edit');
				stream.getTracks().forEach(function(track) {
					track.stop();
				});
			})
			.catch(function(err) {
				var errorNotice = '<h1>Webcam Error</h1><p>We weren\'t able to connect to your webcam. Please click on these icons in your browser in order to allow access and then try again:</p>';
				if (DetectRTC.browser.isChrome)
					errorNotice+='<p><img src="/images/support_chrome_permission.png"/></p>';
				else if (DetectRTC.browser.isFirefox)
					errorNotice+='<p><img src="/images/support_firefox_permission.png"/></p>';
				errorNotice+='<p><span class="fullwidth button request-rtc-permission" onclick="closeModal(\'#modal_edit\');">Close</span></p>';
				$('#modal_edit .modalcontents').html(errorNotice);
			});
	});
}


window.saveDetectRTC = function(detectrtcdata)
{
	$.post('/ajax/_pulse.php',{action:'savedetectrtc', detectrtcdata:JSON.stringify(detectrtcdata)},function(data){

	});
}

window.youtube_parser = function(url){
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return (match&&match[7].length==11)? match[7] : false;
}

window.removeLocationHash = function(){
    var noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL)
}

window.checkemail = function(emailAddress){
	var filter=/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if (filter.test(emailAddress))
		return true;
	else
		return false;
}


window.is_valid_domain_name = function(URL)
{
    //return true; //TODO
	if(URL.match(/^[a-zA-Z0-9\-]+\.[A-Za-z\.]{2,6}$/i))
		return true;
	return false;
}
window.is_valid_url = function(URL)
{
    //return true; //TODO
	if(URL.match(/^[a-zA-Z0-9\-]+$/i))
		return true;
	return false;
}

window.sendPushMessage = function(channel,type,event,data)
{
	$.post('/ajax/_pulse.php',{action:'pushmessage',messagechannel:channel,type:type,event:event,data:data},function(data){
		//console.log(data);
	});
}

window.unixTime = function()
{
	return parseInt(Math.floor(Date.now() / 1000));
}

window.showModal = function(boxRef,modalWidth,fadeinSpeed)
{
	if (modalWidth==undefined || !modalWidth) modalWidth=580;
	if (fadeinSpeed==undefined || !fadeinSpeed) fadeinSpeed=200;

	//$('.window').hide();
	//Get the screen height and width
	var maskHeight = $(document).height();
	var maskWidth = $(window).width();

	var modalMask = $(boxRef).children('.modalmask').eq(0);
	var modalContents = $(boxRef).children('.modalwindow').eq(0);

	if (modalWidth) modalContents.width(modalWidth);

	//Set height and width to mask to fill up the whole screen
	modalMask.css({'width':maskWidth,'height':maskHeight});
	//transition effect
	modalMask.fadeTo("fast",0.9);

	//Get the window height and width
	var winH = $(window).height();
	var winW = $(window).width();

	//Set the popup window to center
	//modalContents.css('top',  winH/2-modalContents.height()/2);
	modalContents.css('max-width',  winW-40);
	modalContents.css('top',  $(window).scrollTop()+30);
	modalContents.css('left', winW/2-modalContents.width()/2);

	//$(boxRef).css('height','200px');
	//transition effect
	modalContents.fadeIn(fadeinSpeed);

	$(boxRef+' .closemodalbutton').click(function(){
		closeModal(boxRef);
		window.location.hash='';
	});
}

window.loadModal = function(urlToLoad,boxRef,modalWidth)
{
	$(boxRef+' .modalcontents').html('<div class="loading"><img src="/images/loading.gif"/> Loading, please wait...</div>');
	showModal(boxRef,modalWidth);
	$(boxRef+' .modalcontents').load(urlToLoad,function(){
		$(boxRef+' .modalcontents .closemodalbutton').click(function(){
			closeModal(boxRef);
			window.location.hash='';
		});
	});
}

window.closeModal = function(boxRef,clearHTML)
{
	if (clearHTML==undefined) clearHTML=true;
	var modalMask = $(boxRef).children('.modalmask').eq(0);
	var modalContents = $(boxRef).children('.modalwindow').eq(0);

	modalMask.hide();
	modalContents.hide();
	if (clearHTML) $(boxRef+' .modalcontents').html('');

	scrollToTopModal();
}

window.scrollToTopModal = function()
{
	var topZindex = 0;
	var topDivId;
	var topDivObject;
	var topDivWindowObject = false;
	$('.ot-modal .modalwindow:visible').each(function(){
		var currentZindex = parseInt($(this).css('z-index'), 10);
		if(currentZindex > topZindex) {
			topZindex = currentZindex;
			topDivWindowObject = $(this);
		}
	});
	if (topZindex==0 || !topDivWindowObject) return false;

	window.scrollTo(0,topDivWindowObject.position().top-20);
}


window.showNotice = function(title,message,type,icon,isModal)
{
	var target = '#modal_notify .modalcontents';

	if (icon===undefined)
		icon = (type=='success'?'fa fa-check-circle':'fa fa-times-circle');

	$(target).html('<div class="notify-icon '+type+'"><i class="'+icon+'"></i></div><div class="notify-container"><div class="title">'+title+'</div><div class="description">'+message+'</div></div>');

	if(!isModal){
		$(target).append('<div class="close-notice">Click anywhere to close this message</div>');
	}
	showModal('#modal_notify');

	if(!isModal){
		$('#modal_notify').click(function(e){
			if(!$(e.target).is('a'))
				closeModal('#modal_notify');
			else
				e.stopPropagation();
		});
	}
}
window.showWaiting = function(title,message)
{
	var target = '#modal_waiting .modalcontents';
	$(target).html('<div class="notify-icon waiting"><i class="fa fa-clock-o"></i></div><div class="notify-container"><div class="title">'+title+'</div><div class="description">'+message+'</div></div>');
	showModal('#modal_waiting',false,0);
}

window.showGrowl = function(message)
{
	$('#growlnotice .message').html(message);
	$('#growlnotice').slideDown(100);
	$('#growlnotice').click(function(){
		$(this).slideUp(200);
	});
}

window.hexToRGB = function(h) {
	let r = 0, g = 0, b = 0;

	// 3 digits
	if (h.length == 4) {
		r = "0x" + h[1] + h[1];
		g = "0x" + h[2] + h[2];
		b = "0x" + h[3] + h[3];
	// 6 digits
	} else if (h.length == 7) {
		r = "0x" + h[1] + h[2];
		g = "0x" + h[3] + h[4];
		b = "0x" + h[5] + h[6];
	}

	return "rgb("+ +r + "," + +g + "," + +b + ")";
}

window.hexAToRGBA = function(h) {
	let r = 0, g = 0, b = 0, a = 1;

	if (h.length == 5) {
		r = "0x" + h[1] + h[1];
		g = "0x" + h[2] + h[2];
		b = "0x" + h[3] + h[3];
		a = "0x" + h[4] + h[4];
	} else if (h.length == 9) {
		r = "0x" + h[1] + h[2];
		g = "0x" + h[3] + h[4];
		b = "0x" + h[5] + h[6];
		a = "0x" + h[7] + h[8];
	}
	a = +(a / 255).toFixed(3);

	return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")";
}


window.setCookie = function(name,value,minutes) {
    var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
window.getCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
window.eraseCookie = function(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}


String.prototype.splice = function (index, string) {
  if (index > 0)
    return this.substring(0, index) + string + this.substring(index, this.length);
  else
    return string + this;
};

window.showV2HelpMessage = function() {
	loadModal('/v2helpcontent','#modal_edit_3',500);
}

window.isEdge = function () {
	return navigator.userAgent.toLowerCase().indexOf("edge") > -1 || navigator.userAgent.toLowerCase().indexOf("edg/") > -1;
}