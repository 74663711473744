$.fn.errorCheck = function( options ) {
	// This is the easiest way to have default options.
	var settings = $.extend({
		// These are the defaults.
		showError: true,
	}, options );

	var includeClass = "required";
	var errorClass = "input_error_highlight";
	var errorMessageClass = "input_error_inline";
	var formCheck = true;
	
	//Reset check variable
	var isError = false;
	var errorMessageContents = "This field is required";
	if ($(this).prop('type') == 'radio' || $(this).prop('type') == 'checkbox')
		var targetElement = $(this).closest('div');
	//else if ($(this).prop('type') == 'select-one')
	//	var targetElement = $(this).parent();
	else
		var targetElement = $(this);
	
	targetElement.prev('.'+errorMessageClass).remove();
	
	if ((!targetElement.is(':visible') || $(this).prop('disabled') || $(this).prop('type')==undefined) && $(this).attr('data-always-validate')==undefined && !$(this).hasClass('selectized')) return true;
	
	if ($(this).attr('data-validation-custom-errormessage')!=undefined && $(this).attr('data-validation-custom-errormessage')!='')
		errorMessageContents = $(this).attr('data-validation-custom-errormessage');

	var valueToCheck = false;
	
	if ($(this).prop('type') == 'radio')
	{
		valueToCheck = $("input:radio[name='"+$(this).attr('name')+"']:checked").val();
		if (!$("input:radio[name='"+$(this).attr('name')+"']:checked").is(':visible')) isError = true; //Selected radio is hidden
	}
	else if ($(this).prop('type') == 'checkbox')
	{
		valueToCheck = $(this).is(':checked');
		if (!$(this).is(':visible')) isError = true; //Selected checkbox is hidden
	}
	else if ($(this).prop('type') == 'select-multiple')
	{
		valueToCheck = $(this).find('option:selected').length;
	}
	else valueToCheck = $(this).val();
	
	
	
	//Overwrite value to check for validation groups
	if ($(this).attr('data-validation-group')!=undefined && $(this).attr('data-validation-group')!='')
	{
		valueToCheck = false;
		$('body').find('input[data-validation-group="'+$(this).attr('data-validation-group')+'"]:checked').each(function(){
			if ($(this).val()!='')
				valueToCheck = $(this).val();
		});
	}
	
	
	//Basic error checking
	if (valueToCheck=='' || valueToCheck===undefined || !valueToCheck)
		isError = true;
	

	if ($(this).attr('data-validation-error')=='true')
	{
		errorMessageContents = $(this).attr('data-validation-errormessage');
		isError = true;
	}
	
	//Custom error checking types
	if ($(this).attr('data-validation-length')!=undefined && (valueToCheck.length<parseInt($(this).attr('data-validation-length'))))
	{
		errorMessageContents = "Please enter at least "+parseInt($(this).attr('data-validation-length'))+" characters";
		isError = true;
	}
	if ($(this).attr('data-validation-minvalue')!=undefined && (parseInt(valueToCheck)<parseInt($(this).attr('data-validation-minvalue'))))
	{
		errorMessageContents = "Minimum value is "+parseInt($(this).attr('data-validation-minvalue'));
		isError = true;
	}
	if ($(this).attr('data-validation-maxvalue')!=undefined && (parseInt(valueToCheck)>parseInt($(this).attr('data-validation-maxvalue'))))
	{
		errorMessageContents = "Maximum value is "+parseInt($(this).attr('data-validation-maxvalue'));
		isError = true;
	}
	
	if ($(this).attr('data-validation-type')=='password')
	{
		var dataToCheck = valueToCheck;
		$(this).closest('form').find("[data-validation-type='password']").each(function(){
			if (dataToCheck!=$(this).val())
			{
				errorMessageContents = "Please enter the same password twice";
				isError = true;
			}
		});
		if (!isError)
		{
			$(this).closest('form').find("[data-validation-type='password']").removeClass(errorClass);
			$(this).closest('form').find("[data-validation-type='password']").prev('.'+errorMessageClass).remove();
		}
	}

	
	if ($(this).attr('data-validation-type')=='email' && !checkemail(valueToCheck))
	{
		errorMessageContents = "Please enter a valid email address";
		isError = true;
	}
		
	if ($(this).attr('data-validation-type')=='emailinuse')
	{
		if (!checkemail(valueToCheck))
		{
			errorMessageContents = "Please enter a valid email address";
			isError = true;
		}
		else
		{
			targetElement.before($('<span class="'+errorMessageClass+'">Please wait, checking that email address</span>').css('left',targetElement.position().left+2));
			var excludeID = $(this).attr('data-exclude');
			var emailtype = $(this).attr('data-email-type');
			$.ajax({
				url: "/ajax/_signup.php?action=emailcheck&exclude="+excludeID+"&emailtype="+emailtype+"&email="+valueToCheck,
				dataType: 'json',
				async: false,
				success: function(data) {
					if(!data.result)
					{
						isError = true;
						errorMessageContents = data.message;
					}
				}
			});
		}
	}

	if (settings.showError)
		$(this).showError({isError:isError,message: errorMessageContents});
	
	return !isError;
};


$.fn.showError = function( options ) {
	// This is the easiest way to have default options.
	var settings = $.extend({
		// These are the defaults.
		message: "This is a required field",
		isError: true,
		targetDiv: false,
	}, options );
		
	var includeClass = "required";
	var errorClass = "input_error_highlight";
	var errorMessageClass = "input_error_inline";
	var formCheck = true;

	if ($(this).prop('type') == 'radio' || $(this).prop('type') == 'checkbox' || settings.targetDiv)
		var targetElement = $(this).closest('div');
	else
		var targetElement = $(this);
	
	var highlightTargetElement = targetElement;
	if ($(this).hasClass('selectized'))
		var highlightTargetElement = targetElement.next('.selectize-control').find('.selectize-input');

	targetElement.prev('.'+errorMessageClass).remove();
	var errorLeft = targetElement.position().left+2;
	
		
	if (settings.isError)
	{
		highlightTargetElement.addClass(errorClass);
		targetElement.before($('<span class="'+errorMessageClass+'">'+settings.message+'</span>').css('left',errorLeft));
		
		return false;
	}
	else
	{
		highlightTargetElement.removeClass(errorClass);
		return true;
	}
};