(function($) {
	
	var t = null;
	$.otDialog = {
		escape_callback: null,
		enter_callback: null,
		active: false,
		close_timer: null,

		//________button / control labels
		labels: {
			ok: 'Ok',
			yes: 'Yes',
			no: 'No',
			cancel: 'Cancel'
		},

		//________element ids
		ids: {
			div_box:	'otDialog_box',
			div_content:	'otDialog_content',
			div_options: 'otDialog_options',
			bt_yes: 'modal_confirm_yes',
			bt_no: 'modal_confirm_no',
			bt_ok: 'otDialog_ok',
			bt_ancel: 'otDialog_ok',
			input: 'otDialog_input'
		},
		
		//________confirm dialog
		confirm: function(message, details, callback_yes, callback_no, yes_button_text) {
			t.create(message,details);

			$('#'+t.ids.bt_yes).focus();
			
			// just redo this everytime in case a new callback is presented
			$('#'+t.ids.bt_yes).unbind().click( function() {
				t.cleanKeypressCallbacks();
				t.close();
				if(callback_yes) callback_yes();
			});
			// redundant method for 'enter' key binding
			t.enter_callback = function() {
				if(callback_yes) callback_yes();
			};

			$('#'+t.ids.bt_no).unbind().click( function() {
				t.cleanKeypressCallbacks();
				t.close();
				if(callback_no) callback_no();
			});
			// redundant method for 'escape' key binding
			t.escape_callback = function() {
				if(callback_no) callback_no();
			};

			if (yes_button_text) $('#'+t.ids.bt_yes).text(yes_button_text);
		},
		

		//________create a dialog box
		create: function(message,details) {
			//t.maintainPosition( $('#modal_confirm') );

			$('#modal_confirm .modalcontents .title').html(message);
			$('#modal_confirm .modalcontents .description').html(details);
			
			showModal('#modal_confirm');

			t.active = true;
		},
		//________close the dialog box
		close: function() {
			t.close_timer = window.setTimeout(function() {
				closeModal('#modal_confirm',false);
				t.clearPosition();
				t.active = false;
			});
		},

		//________position control
		clearPosition: function() {
			$(window).unbind('scroll.otDialog');
		},
		makeCenter: function(object) {
			object.css({
				top: ( (($(window).height() / 2) - ( object.height() / 2 ) )) + ($(document).scrollTop()) + 'px',
				left: ( (($(window).width() / 2) - ( object.width() / 2 ) )) + ($(document).scrollLeft()) + 'px'
			});
		},
		maintainPosition: function(object) {
			t.makeCenter(object);
			
			$(window).bind('scroll.otDialog', function() {
				t.makeCenter(object);
			} );
		},

		//________
		init_done: false,
		check: function() {
			if(t.init_done)
				return;
			else {
				t.init_done = true;
			}

		},
		init: function() {

			
			// keyboard bindings
			$(document).keyup(function(e) {
				if(e.altKey) return;

				if (e.keyCode == 13) {
					t.enterPressed();
				}
				if (e.keyCode == 27) {
					t.escapePressed();
				}
			});
		},
		cleanKeypressCallbacks: function() {
			t.enter_callback = null;
			t.escape_callback = null;
		},
		escapePressed: function() {
			t.close();
			if(t.escape_callback) {
				t.enter_callback = null;
				t.escape_callback();
				t.escape_callback = null;
			}
		},
		enterPressed: function() {
			t.close();
			if(t.enter_callback) {
				t.escape_callback = null;
				t.enter_callback();
				t.enter_callback = null;
			}
		},
	};
	t = $.otDialog;
	$.otDialog.init();
})(jQuery);
