;$.fn.ajaxSearch = function(options,callback) {

	var settings = $.extend({
		// These are the defaults.
		requestURL: "",
		searchInputID: "",
		searchVariable: "searchString",
		useFunction: "",
		width: "100%",
	}, options );

	var ajaxRequest;

	$(this).parent().find('ul.ajaxresults').remove();
	
	var thisInput = $(this);
	$(this).after('<ul class="ajaxresults"><li></li></ul>');
	var thisResults = $(this).next('ul.ajaxresults');
	thisResults.css('width',settings.width).hide();

	thisResults.on('click','li',function(){
		
		if (typeof callback == 'function')
			callback.call(this,this,$(this).data('details'));

		if (typeof window[settings.useFunction] == 'function')
			window[settings.useFunction]($(this).data('details'));

		//window[settings.useFunction]($(this).data('details'));
		thisResults.hide();
		$('#'+settings.searchInputID).val('').focus();
	});

	var actionKeys = [9,13,37,38,39,40];

	$(this).keyup(function(e){
		
		var keyCode = e.keyCode || e.which;

		if (actionKeys.indexOf(keyCode)<0 && $(this).val().length>=2)
		{
			if (ajaxRequest) ajaxRequest.abort();

			var ajaxArgs = {};
			ajaxArgs[settings.searchVariable] = $('#'+settings.searchInputID).val();

			ajaxRequest = $.post(settings.requestURL,ajaxArgs,function(data){
				thisResults.html('').show();
				var results = JSON.parse(data);
				for (i in results)
					thisResults.append('<li data-id="'+results[i].id+'" data-details=\''+JSON.stringify(results[i]).replace(/'/g,"")+'\'>'+results[i].title+'</li>');
			});
		}
		else if (actionKeys.indexOf(keyCode)>-1 && thisResults.is(':visible') && thisResults.find('li').length>0)
			e.preventDefault();
	});
	$(this).keydown(function(e){
		var keyCode = e.keyCode || e.which;

		if (keyCode == 39 || keyCode == 40) //DOWN key
		{
			e.preventDefault();
			if (thisResults.find('li.active').next().length == 0)
			{
				thisResults.find('li.active').removeClass('active');
				thisResults.find('li:first-child').addClass('active');
			}
			else
				thisResults.find('li.active').removeClass('active').next().addClass('active');
		}
		else if (keyCode == 37 || keyCode == 38) //UP key
		{
			e.preventDefault();
			if (thisResults.find('li.active').prev().length == 0)
			{
				thisResults.find('li.active').removeClass('active');
				thisResults.find('li:last-child').addClass('active');
			}
			else
				thisResults.find('li.active').removeClass('active').prev().addClass('active');
		}
		else if ((keyCode == 13 || keyCode == 9) && thisResults.is(':visible') && thisResults.find('li').length>0) {  //ENTER or TAB key is pressed
			e.preventDefault();
			window[settings.useFunction](thisResults.find('li.active').data('details'));
			thisResults.hide();
			$('#'+settings.searchInputID).val('').focus();
		}
	});
};