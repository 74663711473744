function Speedtest() { }

Speedtest.prototype = {
	start: function() {
		if (this._state == 3) throw "Test already running";
		this.worker = new Worker("/js/includes/speedtest_worker.js?r=" + Math.random());
		this.worker.onmessage = function(e) {
			if (e.data === this._prevData) return;
			else this._prevData = e.data;
			var data = JSON.parse(e.data);
			try {
				if (this.onupdate) this.onupdate(data);
			} catch (e) {
				console.error("Speedtest onupdate event threw exception: " + e);
			}
			if (data.testState >= 4) {
				try {
					if (this.onend) this.onend(data.testState == 5,data);
				} catch (e) {
					console.error("Speedtest onend event threw exception: " + e);
				}
				clearInterval(this.updater);
				this._state = 4;
			}
		}.bind(this);
		this.updater = setInterval(
			function() {
				this.worker.postMessage("status");
			}.bind(this),
			200
		);
		this._state = 3;
		this.worker.postMessage("start");
	},
}
window.Speedtest = Speedtest;